export const baseStoreData = {
  "fruit trees": {
    apple: [
      {
        Variety: "Ashmead's Kernel",
        Price: "0.00",
        Inventory: "0",
        Size: "Whip 1M +",
        Form: "",
        Rootstock: "B118 Semi-Dwarf",
        Pollination: "Needs Pollinator",
        Description:
          "The Ashmead's Kernel is a traditional English apple variety, renowned for its distinctive, rich and aromatic flavor with a balance of sharpness and sweetness",
      },
      {
        Variety: "Belle de Boskoop",
        Price: "0.00",
        Inventory: "0",
        Size: "Whip 1M +",
        Form: "",
        Rootstock: "B118 Semi-Dwarf",
        Pollination: "Needs Pollinator",
        Description:
          "Belle de Boskoop apples are tart and aromatic with a thick, greenish-yellow skin, perfect for baking and cooking.",
      },
      {
        Variety: "Cortland Nova Red",
        Price: "0.00",
        Inventory: "0",
        Size: "Whip 1M +",
        Form: "",
        Rootstock: "EMLA 106 Semi-Dwarf",
        Pollination: "Needs Pollinator",
        Description:
          "Cortland Nova Red apples are crisp, slightly tart, and boast a vibrant red skin, making them a popular choice for fresh eating and salads",
      },
      {
        Variety: "Cox's Orange Pippin",
        Price: "0.00",
        Inventory: "0",
        Size: "Whip 50-80cm",
        Form: "",
        Rootstock: "EMLA 111 Semi-Dwarf",
        Pollination: "Needs Pollinator",
        Description:
          "Cox's Orange Pippin apples are prized for their sweet, aromatic, and honeyed flavor, often considered one of the finest dessert apples.",
      },
      {
        Variety: "Florina",
        Price: "0.00",
        Inventory: "0",
        Size: "Whip 1M +",
        Form: "",
        Rootstock: "EMLA 106 Semi-Dwarf",
        Pollination: "Needs Pollinator",
        Description:
          "Florina apples are known for their distinctive, rich red color, crisp texture, and sweet-tart taste.",
      },
      {
        Variety: "Freedom",
        Price: "0.00",
        Inventory: "0",
        Size: "Whip 1M +",
        Form: "",
        Rootstock: "EMLA 106 Semi-Dwarf",
        Pollination: "Needs Pollinator",
        Description:
          "Freedom apples are a disease-resistant variety known for their sweet, juicy flesh and versatility in both fresh consumption and cooking.",
      },
      {
        Variety: "Golden Russet",
        Price: "0.00",
        Inventory: "0",
        Size: "Whip 1M +",
        Form: "",
        Rootstock: "B118 Semi-Dwarf",
        Pollination: "Needs Pollinator",
        Description:
          "Golden Russet apples have a rough, golden-brown skin and a sweet, nutty flavor that's excellent for cider and fresh eating.",
      },
      {
        Variety: "Northern Spy",
        Price: "0.00",
        Inventory: "0",
        Size: "Whip 1M +",
        Form: "",
        Rootstock: "B118 Semi-Dwarf",
        Pollination: "Needs Pollinator",
        Description:
          "Northern Spy apples are large, tart, and ideal for baking, known for their exceptional pie-making qualities.",
      },
      {
        Variety: "Pink Pearl",
        Price: "0.00",
        Inventory: "0",
        Size: "Whip 1M +",
        Form: "",
        Rootstock: "EMLA 106 Semi-Dwarf",
        Pollination: "Needs Pollinator",
        Description:
          "Pink Pearl apples are known for their pink flesh and tart, mildly sweet flavor, making them a unique and visually striking choice.",
      },
      {
        Variety: "Wealthy",
        Price: "0.00",
        Inventory: "0",
        Size: "Whip 1M +",
        Form: "",
        Rootstock: "EMLA 106 Semi-Dwarf",
        Pollination: "Needs Pollinator",
        Description:
          "Wealthy apples are crisp, moderately tart, and excellent for pies and sauces, with a history dating back to the 19th century.",
      },
    ],
    pear: [
      {
        Variety: "Bartlett",
        Price: "0.00",
        Inventory: "0",
        Size: "Whip 1M +",
        Form: "",
        Rootstock: "",
        Pollination: "Self Pollinating",
        Description:
          "Bartlett pears are a timeless favorite, known for their classic, sweet, and juicy flavor. These pear trees are a must-have for any orchard, offering versatile and delicious fruit.",
      },
      {
        Variety: "Flemish Beauty",
        Price: "0.00",
        Inventory: "0",
        Size: "",
        Form: "",
        Rootstock: "",
        Pollination: "Needs Pollinator",
        Description:
          "Flemish Beauty pears are celebrated for their sweet and flavorful taste, making them a delightful addition to any garden. These trees produce pears that are perfect for enjoying fresh or using in various culinary creations.",
      },
      {
        Variety: "Clapp's Favorite",
        Price: "0.00",
        Inventory: "0",
        Size: "",
        Form: "",
        Rootstock: "",
        Pollination: "Needs Pollinator",
        Description:
          "Clapp's Favorite pears are a true delight with their deliciously sweet and juicy fruit. These pear trees bear some of the finest dessert pears, making them a top choice for orchard enthusiasts.",
      },
    ],
    peach: [
      {
        Variety: "Redhaven",
        Price: "0.00",
        Inventory: "0",
        Size: "",
        Form: "",
        Rootstock: "",
        Pollination: "Self Pollinating",
        Description:
          "Redhaven peach trees are renowned for their exceptionally sweet and juicy peaches. These trees offer the quintessential taste of summer, making them a popular choice for those who crave delectable, tree-ripened fruit.",
      },
    ],
    "sour cherry": [
      {
        Variety: "Montmorency 1 year",
        Price: "0.00",
        Inventory: "0",
        Size: "",
        Form: "",
        Rootstock: "",
        Pollination: "Self Pollinating",
        Description:
          "Montmorency sour cherry trees, especially when young, produce tart and versatile cherries. These trees are perfect for those who enjoy the tangy goodness of sour cherries, ideal for pies, jams, and more.",
      },
      {
        Variety: "Montmorency 2 year",
        Price: "0.00",
        Inventory: "0",
        Size: "",
        Form: "",
        Rootstock: "",
        Pollination: "",
        Description:
          "Montmorency sour cherry trees continue to provide that cherished tartness as they mature. These trees are a preferred choice for anyone who appreciates the unique flavor of sour cherries and desires them fresh from their own garden.",
      },
      {
        Variety: "English Morello",
        Price: "0.00",
        Inventory: "0",
        Size: "",
        Form: "",
        Rootstock: "",
        Pollination: "",
        Description:
          'English Morello cherries are a culinary treasure, ideal for making preserves and desserts. These cherry trees bear fruit perfect for creating jams, pies, and delightful confections."',
      },
    ],
    apricot: [
      {
        Variety: "Goldcot",
        Price: "0.00",
        Inventory: "0",
        Size: "",
        Form: "",
        Rootstock: "",
        Pollination: "",
        Description:
          "Goldcot apricot trees are prized for their sweet and flavorful apricots. These trees offer a delightful harvest, with apricots that are perfect for enjoying fresh or using in your favorite apricot recipes.",
      },
      {
        Variety: "Moorpark",
        Price: "0.00",
        Inventory: "0",
        Size: "",
        Form: "",
        Rootstock: "",
        Pollination: "",
        Description:
          "Moorpark apricot trees yield sweet apricots and require pollination for optimal fruit set. Their fruit is not only delicious but also versatile in the kitchen, making them a choice selection for fruit enthusiasts.",
      },
      {
        Variety: "Harlayne",
        Price: "0.00",
        Inventory: "0",
        Size: "",
        Form: "",
        Rootstock: "",
        Pollination: "",
        Description:
          "Harlayne apricot trees offer a tasty and enjoyable fresh fruit experience. These trees provide apricots that are bursting with sweetness and are perfect for snacking and sharing with family and friends.",
      },
      {
        Variety: "Harojoy",
        Price: "0.00",
        Inventory: "0",
        Size: "",
        Form: "",
        Rootstock: "",
        Pollination: "",
        Description:
          "Harojoy apricot trees produce delicious apricots that are ideal for fresh consumption. With their delightful flavor and juicy flesh, these trees are a valuable addition to any orchard.",
      },
    ],
    "sweet cherry": [
      {
        Variety: "van",
        Price: "0.00",
        Inventory: "0",
        Size: "",
        Form: "",
        Rootstock: "",
        Pollination: "",
        Description:
          "Van sweet cherry trees are known for their sweet and juicy cherries, perfect for snacking and adding a burst of flavor to your culinary creations. These trees are a delightful choice for cherry enthusiasts.",
      },
      {
        Variety: "Tehranivee",
        Price: "0.00",
        Inventory: "0",
        Size: "",
        Form: "",
        Rootstock: "",
        Pollination: "",
        Description:
          "Tehranivee sweet cherry trees offer flavorful cherries that are great for snacking and enjoying fresh. These trees provide a delicious taste of summer in every bite.",
      },
      {
        Variety: "Vogue",
        Price: "0.00",
        Inventory: "0",
        Size: "",
        Form: "",
        Rootstock: "",
        Pollination: "",
        Description:
          "Vogue sweet cherry trees produce delectable cherries that are versatile and can be used in various culinary applications. These trees are perfect for those who appreciate the versatility of sweet cherries in their dishes.",
      },
    ],
    plum: [
      {
        Variety: "Mount Royal",
        Price: "0.00",
        Inventory: "0",
        Size: "",
        Form: "",
        Rootstock: "",
        Pollination: "",
        Description:
          "Mount Royal plum trees are prized for their sweet and juicy plums, perfect for eating fresh and incorporating into your favorite plum recipes. These trees offer a delightful taste of summer.",
      },
      {
        Variety: "Toka",
        Price: "0.00",
        Inventory: "0",
        Size: "",
        Form: "",
        Rootstock: "",
        Pollination: "",
        Description:
          "Toka plum trees produce delicious plums that are perfect for fresh eating. With their sweet and juicy flesh, these plums are a favorite among plum enthusiasts.",
      },
      {
        Variety: "Damson",
        Price: "0.00",
        Inventory: "0",
        Size: "",
        Form: "",
        Rootstock: "",
        Pollination: "",
        Description:
          "Damson plum trees are known for their tartness, making them ideal for making jams and jellies. These trees are perfect for preserving the rich flavor of plums in your homemade preserves.",
      },
      {
        Variety: "Italian",
        Price: "0.00",
        Inventory: "0",
        Size: "",
        Form: "",
        Rootstock: "",
        Pollination: "",
        Description:
          "Italian plum trees bear sweet and flavorful plums that are great for various culinary uses. Whether eaten fresh or used in cooking and baking, these plums are a versatile choice.",
      },
      {
        Variety: "Victory",
        Price: "0.00",
        Inventory: "0",
        Size: "",
        Form: "",
        Rootstock: "",
        Pollination: "",
        Description:
          "Victory plum trees produce sweet and juicy plums, perfect for snacking or baking. These plums are a victory for those who love the delightful taste of fresh, tree-ripened fruit.",
      },
    ],
  },
  "nut & other trees": [
    {
      Variety: "Black Walnut",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "",
      Rootstock: "",
      Pollination: "",
      Description:
        "Black Walnut trees are prized for their rich, flavorful nuts, which have a unique earthy taste. These trees not only provide delicious nuts for culinary use but also offer valuable hardwood that's highly sought after in woodworking and furniture making.",
    },
    {
      Variety: "English Walnut",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "",
      Rootstock: "",
      Pollination: "",
      Description:
        "English Walnut trees are known for their versatile and delectable nuts, making them a favorite choice for both snacking and enhancing the flavor of various dishes. These trees are a delightful addition to any orchard.",
    },
    {
      Variety: "Black Locust",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "",
      Rootstock: "",
      Pollination: "",
      Description:
        "Black Locust trees are valued not for their nuts or fruits but for their durable, strong wood. This hardwood is renowned in the construction and woodworking industries for its strength and resistance to decay, making it an excellent choice for outdoor projects and more.",
    },
    {
      Variety: "Sugar Maple",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "",
      Rootstock: "",
      Pollination: "",
      Description:
        "Sugar Maple trees are celebrated for their role in maple syrup production, with their sap being the primary ingredient in this beloved sweet treat. Additionally, these trees grace landscapes with their stunning, vibrant fall foliage, adding natural beauty to any setting.",
    },
    {
      Variety: "Paw Paw",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "",
      Rootstock: "",
      Pollination: "",
      Description:
        "Paw Paw trees bear unique, tropical-flavored fruit that's often described as a cross between a banana and a mango. These trees offer a delectable taste experience, especially when the ripe fruit is savored fresh.",
    },
    {
      Variety: "Persimmons",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "",
      Rootstock: "",
      Pollination: "",
      Description:
        "Persimmon trees provide sweet and flavorful fruits, with some varieties suitable for fresh eating and others perfect for cooking and baking. These trees are a versatile addition to any garden, offering delicious options for both sweet and savory dishes.",
    },
    {
      Variety: "Ultra Northern Pecan",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "",
      Rootstock: "",
      Pollination: "",
      Description:
        "Ultra Northern Pecan trees produce generously sized and delicious pecans, making them an ideal choice for nut enthusiasts and anyone who enjoys the rich, buttery flavor of pecans.",
    },
    {
      Variety: "Swamp White Oak",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "",
      Rootstock: "",
      Pollination: "",
      Description:
        "Swamp White Oak trees are valued for their strong and durable wood, which has applications in construction and furniture making. These trees are not only practical but also contribute to the natural beauty of the landscape.",
    },
    {
      Variety: "Kentucky Coffeetree",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "",
      Rootstock: "",
      Pollination: "",
      Description:
        "Kentucky Coffee trees provide beans that can be roasted to create a coffee substitute with a unique and pleasant flavor. These trees offer a fascinating alternative for those seeking caffeine-free coffee options.",
    },
    {
      Variety: "Hazelnut",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "",
      Rootstock: "",
      Pollination: "",
      Description:
        "Hazelnut bushes produce delectable nuts that are commonly used in baking, confections, and as a healthy snack. These bushes are a wonderful addition to gardens, providing a source of nutrient-rich nuts.",
    },
  ],
  "perennials berries & bushes": [
    {
      Variety: "Aronia (Viking)",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "",
      Rootstock: "",
      Pollination: "",
      Description:
        "Viking Aronia bushes yield antioxidant-rich berries, perfect for making nutritious juices, jams, and other health-conscious creations. These bushes are an excellent choice for those looking to incorporate superfoods into their diet.",
    },
    {
      Variety: "Aronia (Mckenzie)",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "",
      Rootstock: "",
      Pollination: "",
      Description:
        "McKenzie Aronia bushes also produce nutritious berries that can be used in a variety of culinary applications. These bushes offer the opportunity to enjoy the health benefits of aronia berries.",
    },
    {
      Variety: "Seabuckthorn",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "",
      Rootstock: "",
      Pollination: "",
      Description:
        "Sea Buckthorn bushes yield tart and vitamin C-rich berries, prized for their versatility in both culinary and medicinal preparations. These bushes are a valuable addition to any garden or orchard.",
    },
    {
      Variety: "Arctic Kiwi",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "",
      Rootstock: "",
      Pollination: "",
      Description:
        "Arctic Kiwi vines offer small, sweet, and fuzzy kiwi fruits that can be enjoyed fresh or used in desserts. These vines provide a unique and refreshing fruiting experience.",
    },
    {
      Variety: "Elderberry (Scotia)",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "3 Gallon Pot",
      Rootstock: "",
      Pollination: "",
      Description:
        "Scotia Elderberry bushes produce dark and flavorful berries that are ideal for making elderberry syrup and wine. These bushes are a valuable addition to gardens, offering both culinary and health-related benefits.",
    },
    {
      Variety: "Elderberry (Kent)",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "3 Gallon Pot",
      Rootstock: "",
      Pollination: "",
      Description:
        'Kent Elderberry bushes are known for their tasty berries, suitable for various culinary creations. These bushes offer the opportunity to enjoy the delicious flavor of elderberries in your favorite dishes.",',
    },
    {
      Variety: "Elderberry (Wild)",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "2 Gallon Pot",
      Rootstock: "",
      Pollination: "",
      Description:
        "Wild Elderberry bushes produce small, dark berries that are used for making jams, jellies, and medicinal remedies. These bushes connect us to the traditions of wildcrafting and natural remedies.",
    },
    {
      Variety: "Black Currant (Titania)",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "4 Gallon Pot",
      Rootstock: "",
      Pollination: "",
      Description:
        "Titania Black Currant bushes produce sweet and tangy berries that are excellent for making flavorful jams and desserts. These bushes are a favorite among gardeners for their versatile culinary uses.",
    },
    {
      Variety: "Red Current (Red Lake)",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "4 Gallon Pot",
      Rootstock: "",
      Pollination: "",
      Description:
        "Red Lake Red Currant bushes yield vibrant red berries, perfect for making delicious jams, jellies, and pastry fillings. These bushes add color and flavor to your culinary creations.",
    },
    {
      Variety: "Quince (Giant Zagreb)",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "",
      Rootstock: "",
      Pollination: "",
      Description:
        "Giant Zagreb Quince bushes produce aromatic fruit that's used in jams, jellies, and culinary preparations. These bushes offer a delightful taste of quince, a fruit with a unique and captivating flavor.",
    },
    {
      Variety: "Rhubarb",
      Price: "0.00",
      Inventory: "0",
      Size: "3 Crowns",
      Form: "Tubor",
      Rootstock: "",
      Pollination: "",
      Description:
        "Rhubarb plants provide tart stalks that are commonly used in pies, crisps, and other desserts. These perennial plants are a dependable source of rhubarb for all your baking needs.",
    },
    {
      Variety: "Jerusalem Artichoke",
      Price: "0.00",
      Inventory: "0",
      Size: "2 Lbs +",
      Form: "Tubor",
      Rootstock: "",
      Pollination: "",
      Description:
        "Jerusalem Artichoke plants produce knobby tubers that can be roasted, mashed, or used in various dishes. These plants offer a versatile and less common root vegetable option for your garden.",
    },
    {
      Variety: "Comfrey",
      Price: "0.00",
      Inventory: "0",
      Size: "4 Crowns",
      Form: "Tubor",
      Rootstock: "",
      Pollination: "",
      Description:
        "Comfrey plants are known for their medicinal properties, with their leaves used in salves and poultices to promote healing and reduce inflammation. These plants have a long history of use in traditional herbal medicine.",
    },
    {
      Variety: "Lilac",
      Price: "0.00",
      Inventory: "0",
      Size: '6-12"',
      Form: "Bare Root",
      Rootstock: "",
      Pollination: "",
      Description:
        "Lilac bushes are cherished for their fragrant and colorful blossoms, making them a lovely addition to gardens. These bushes provide not only visual beauty but also a delightful scent that fills the air in spring.",
    },
    {
      Variety: "Horseradish",
      Price: "0.00",
      Inventory: "0",
      Size: "3 Crowns",
      Form: "Tubor",
      Rootstock: "",
      Pollination: "",
      Description:
        "Horseradish plants yield pungent roots that are used to make spicy condiments and sauces. These plants are perfect for those who enjoy adding a kick to their culinary creations.",
    },
    {
      Variety: "Mint",
      Price: "0.00",
      Inventory: "0",
      Size: '1-3" Root Mass',
      Form: "Bare Root",
      Rootstock: "",
      Pollination: "",
      Description:
        "Mint plants offer aromatic leaves that can be used to make refreshing teas, flavor dishes, and garnish desserts. These versatile herbs are a popular choice for culinary and beverage purposes.",
    },
    {
      Variety: "Speeriment",
      Price: "0.00",
      Inventory: "0",
      Size: '1-3" Root Mass',
      Form: "Bare Root",
      Rootstock: "",
      Pollination: "",
      Description:
        "Spearmint plants have a milder minty flavor compared to other mint varieties, making them perfect for beverages, salads, and as a culinary herb. These plants add a fresh and pleasant note to your dishes and drinks.",
    },
  ],
  rootstock: [
    {
      Variety: "Cherry Rootstock",
      Price: "0.00",
      Inventory: "0",
      Size: '5/8" + Thick',
      Form: "Bare Root",
      Rootstock: "Mazzard",
      Pollination: "",
      Description: "-",
    },
    {
      Variety: "Plum Rootstock",
      Price: "0.00",
      Inventory: "0",
      Size: '1/2 " + Thick',
      Form: "Bare Root",
      Rootstock: "Myrobalan",
      Pollination: "",
      Description: "-",
    },
  ],
  supplies: [
    {
      Variety: "Tree Gaurds",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "",
      Rootstock: "",
      Pollination: "",
      Description: "-",
    },
    {
      Variety: "Jute Multch Pads",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "",
      Rootstock: "",
      Pollination: "",
      Description: "-",
    },
    {
      Variety: "Tree Stakes",
      Price: "0.00",
      Inventory: "0",
      Size: "",
      Form: "",
      Rootstock: "",
      Pollination: "",
      Description: "-",
    },
  ],
};
